<template>
  <draggable
      class="list-group"
      tag="ul"
      v-model="formsToSort"
      v-bind="dragOptions"
      @start="drag = true"
      @end="drag = false"
      :disabled="disabled"
  >
    <transition-group type="transition" :name="!drag ? 'flip-list' : null">
      <AttachmentFile
          v-for="(form, index) in formsToSort"
          :key="`file_item_${index}`"
          :class="{ cursorGrab: !disabled }"
          :index="index"
          :form="form"
          :removeFile="removeFile"
          :changeName="changeName"
      />
    </transition-group>
  </draggable>
</template>

<script>
export default {
  props: {
    forms: {
      type: Array,
      default: () => []
    },
    removeFile: {
      type: Function,
      default: () => {}
    },
    changeName: {
      type: Function,
      default: () => {}
    },
    showDistinctType: Boolean
  },
  components: {
    AttachmentFile: () => import('./AttachmentFile.vue'),
    draggable: () => import('vuedraggable')
  },
  data: () => ({
    drag: false,
    disabled: true,
  }),

  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      }
    },
    formsToSort: {
      get() {
        return this.forms
      },
      set(value) {
        const items = value.map((el, index) => ({
          ...el,
          position: index
        }))
        const orderedItems = items.sort((a, b) => a.position - b.position)
        this.$emit('update-forms', orderedItems)
      }
    },
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.list-group {
  min-height: 20px;
}
.cursorGrab {
  cursor: grab !important;
}
</style>
